<template>
  <form>
    <b-field grouped class="column-direction-touch">
      <b-field label="Enquadramento da Empresa">
        <b-select
          disabled
          expanded
          placeholder="Enquadramento da Empresa"
          :value="companyHistory.companySize"
        >
          <option
            v-for="companySize in companySizeOptions"
            :key="companySize.id"
            :value="companySize.value"
            >{{ companySize.name }}</option
          >
        </b-select>
      </b-field>

      <b-field
        label="Simples Nacional"
        v-show="simpleNationalOption"
        :value="companyHistory.companySize"
      >
        <b-switch size="is-medium" disabled :value="companyHistory.simpleNational" />
      </b-field>
      <b-field label="Data Registro" v-show="companyHistory.simpleNational">
        <b-datepicker
          editable
          placeholder="Data Registro"
          icon="calendar-today"
          v-mascara:data
          disabled
          :value="companyHistory.simpleNationalDate"
          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
        ></b-datepicker>
      </b-field>
    </b-field>
  </form>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ViewCompanyType',
  data() {
    const companySizeOptions = [
      { id: 1, name: 'ME', value: 'ME' },
      { id: 2, name: 'MEI', value: 'MEI' },
      { id: 3, name: 'EPP', value: 'EPP' },
      { id: 4, name: 'Outros', value: 'OUTROS' },
      { id: 5, name: 'Cooperativa', value: 'COOPERATIVA' },
      {
        id: 6,
        name: 'Cooperativa Direito de Preferência',
        value: 'COOPERATIVA_PREFERENCIA',
      },
    ];

    return {
      companySizeOptions,
    };
  },
  computed: {
    ...mapState('companies', ['companyHistory']),
    simpleNationalOption() {
      return ['ME', 'EPP'].indexOf(this.companyHistory.companySize) >= 0;
    },
  },
};
</script>
